<template>
  <div class="component-productCenter">
    <img src="./product.jpg" style="width: 100%" alt="">
    <div @click="handleGoPage(1)" style="width: 35.5%;height: 28%;z-index: 100;position: absolute;top: 26%;left: 14.8%;cursor: pointer"></div>
    <div @click="handleGoPage(2)" style="width: 35.5%;height: 28%;z-index: 100;position: absolute;top: 26%;left: 52.5%;cursor: pointer"></div>
    <div @click="handleGoPage(4)" style="width: 35.5%;height: 28%;z-index: 100;position: absolute;top: 59%;left: 14.8%;cursor: pointer"></div>
    <div @click="handleGoPage(3)" style="width: 35.5%;height: 28%;z-index: 100;position: absolute;top: 59%;left: 52.5%;cursor: pointer"></div>
  </div>
</template>

<script>
export default {
  name: "productCenter",
  components: {},
  props: {},
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  filters: {},
  watch: {},
  computed: {},
  methods: {
    handleGoPage(index){
      this.$router.push(`/views/productCenter/page${index}`)
    }
  },
}
</script>

<style lang="scss" scoped>
.component-productCenter {

}
</style>
<style lang="scss">
.component-productCenter_body { //dialog使用 customClass="xxx_body"

}
</style>
